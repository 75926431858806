import React, {useState} from "react";
import {Col, Container, Modal, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ResultsModal from "./resultsModal";
import {triggerAction} from "../util/api";
import {useAuth0} from "@auth0/auth0-react";

const ConfirmModal = (props) => {
    let {show, handleClose, size, modalTitle, groupId, includeSubGroups, action, projectName, projectId, body} = props;

    const [results, setResults] = useState([])

    const [showResults, setShowResults] = useState(false)
    const {getAccessTokenSilently} = useAuth0();


    const handleSubmit = async (action, groupId, includeSubGroups) => {
        setShowResults(true)
        handleClose()
        console.log(`Going to submit as follows: action: ${action}, project_id: ${projectId} group_id: ${groupId}, subgroups?: ${includeSubGroups}`)
        setResults(<p>Action in Progress..</p>)
        const token = await getAccessTokenSilently();
        triggerAction(action, projectId, groupId, includeSubGroups, projectName, token).then(r => {
            let innerBody
            let result = JSON.parse(r.result)
            if (action === "create") {
                if (r.Status === "success") {
                    innerBody = (
                        <>
                            <Container>
                                <h3>Action {r.Status}</h3>
                                <p>Project ID: {result.id}</p>
                                <p>Project Name: {result.name}</p>
                                <p>Project Group: {result.namespace}</p>
                                <p><a href={result.url}>{result.url}</a></p>
                                <p>{result.full_name}</p>
                            </Container></>)
                } else {
                    innerBody = (
                        <><Container>
                            <h3>Action {r.Status}</h3>
                            <p><b>Reason:</b> {result}</p>
                        </Container>
                        </>
                    )
                }
            } else {
                    innerBody = (
                        <Container>
                            <h3>Action {r.Status}!</h3>
                            <Row>
                                <Col>
                                    <h3>Successful:</h3>
                                    {result.successful.map(item => (
                                        <li key={item.name}><font size={+2}>Project Name: </font>{item.name}<br/><font
                                            size={+2}> Reason:</font> {item.reason}</li>))}
                                </Col>
                                <Col>
                                    <h3>Unsuccessful:</h3>
                                    {result.unsuccessful.map(item => (
                                        <li key={item.name}><b>Project Name:</b> {item.name}<br/>
                                            <p2><b>
                                                <text style={{paddingLeft: 20}}>Reason:</text>
                                            </b></p2>
                                            {item.reason}</li>))}
                                </Col>
                            </Row>
                        </Container>)
            }
            setResults(innerBody)
        })
    }

    return (
        <>
            <Modal onClose={handleClose} size={size} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {`${modalTitle}`}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {body}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary"
                            onClick={() => handleSubmit(action, groupId, includeSubGroups)}>Confirm</Button>
                </Modal.Footer>
            </Modal>
            <ResultsModal
                show={showResults}
                handleClose={() => setShowResults(false)}
                handleShow={() => setShowResults(true)}
                results={results}
                size={"lg"}
            >
            </ResultsModal>
        </>
    );
};

export default ConfirmModal;