import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import App from "./components/App";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Auth0Provider
        domain="dunelm-platform.eu.auth0.com"
        clientId="10bB3BxcF9fSg1hDOwGFV5coBxLEDnaa"
        redirectUri={window.location.origin}
        audience="https://gitlab-tool.dunelm.io"
    >
        <App/>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
