export function long_poll(action, req_id, token) {

    const delay = ms => new Promise(res => setTimeout(res, ms));

    let pollUrl = `https://gitlab-tool-api.dunelm.io/api/status?req_id=${req_id}&action=${action}`
    let delayms = 3000
    if (action === "groups") {
        delayms = 500
    }
    console.log(`Polling for uuid: ${req_id}`)
    return new Promise(function (resolve, reject) {
        fetch(pollUrl, {headers: {Authorization: `Bearer ${token}`}})
            .then(res => res.json()).catch(error => {
            console.log(error)
        })
            .then(async data => {
                    console.log(data.Status)
                    if ((data.Status !== 'success') && (data.Status !== 'failed')) {
                        await delay(delayms)
                        data = await long_poll(action, req_id, token)
                    }
                    resolve(data)
                }
            ).catch(error => {
            console.log(error)
        });

    })
}

export function triggerAction(action, projectId, groupId, includeSubGroups, projectName, token) {
    console.log(token)
    let url
    if (projectId) {
        url = `https://gitlab-tool-api.dunelm.io/api/${action}?project_id=${projectId}`
    } else if (groupId) {
        url = `https://gitlab-tool-api.dunelm.io/api/${action}?group_id=${groupId}&get_subgroups=${includeSubGroups}&project_name=${projectName}`
    }
    return new Promise(function (resolve, reject) {
            fetch(url, {headers: {Authorization: `Bearer ${token}`}})
                .then(res => res.json())
                .then(data => {
                    let uuid = data.uuid
                    long_poll(action, uuid, token).then(r => {
                        resolve(r)
                    })
                })
        }
    )
}