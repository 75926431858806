import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import React, {
    useState,
    useEffect
} from "react";
import TableButton from "./tableButton";
import {useAuth0} from "@auth0/auth0-react";

const TableWithData = () => {

    const {SearchBar} = Search
    const {getAccessTokenSilently} = useAuth0();


    const columns = [{
        dataField: "_projectId",
        hidden: true
    },
        {
            dataField: "projectId",
            text: "Project ID",
            sort: true
        },
        {
            dataField: "projectName",
            text: "Project name",
            sort: true
        },
        {
            dataField: "dateTime",
            text: "Time of Backup",
            sort: true
        },
        {
            dataField: "protectedBranchName",
            text: "Branch Name",
            sort: true
        },
        {
            dataField: "attributes",
            text: "Attributes"
        },
        {
            dataField: "",
            text: "Actions",
            formatter: (value, row,) => {
                let curID = row["projectId"]
                let projectName = row["projectName"]
                console.log(projectName)
                return (<>
                    <TableButton
                        type={"success"}
                        buttonName={"Backup"}
                        id={`{btn-backup-project-${curID}`}
                        projectName={projectName}
                    >
                    </TableButton>
                    <TableButton
                        type={"danger"}
                        buttonName={"Thaw"}
                        id={`{btn-thaw-project-${curID}`}
                        projectName={projectName}
                    ></TableButton>
                    <TableButton
                        type={"primary"}
                        buttonName={"Freeze"}
                        id={`{btn-freeze-project-${curID}`}
                        projectName={projectName}
                    ></TableButton>
                </>)


            },
        }
    ];


    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            return fetch("https://gitlab-tool-api.dunelm.io/api/tabledata", {headers: {Authorization: `Bearer ${token}`}}
            )
                .then(res => res.json()
                )
                .catch(function (error) {
                    console.log(error);
                }).then(data => {
                    console.log(data)
                    return data
                })
        }
        fetchData().then((newData) => setData(newData))
    }, [getAccessTokenSilently]);


    return (
        <ToolkitProvider
            keyField="projectId"
            data={data}
            columns={columns}
            search
        >
            {
                props => (
                    <div>
                        <h3>Repository Settings Backup</h3>
                        <SearchBar {...props.searchProps} />
                        <hr/>
                        <BootstrapTable {...props.baseProps}
                                        striped hover condensed pagination={paginationFactory()}
                        />
                    </div>
                )
            }
        </ToolkitProvider>
    );
};

export default TableWithData;