import {createContext, useEffect, useState} from "react";
import {long_poll} from "../util/api";
import {useAuth0} from "@auth0/auth0-react";

const DropDownContext = createContext([{}])


export function DropDownProvider({children}) {
    const {getAccessTokenSilently} = useAuth0();

    const [dropDown, setDropDown] = useState([{id: 0, full_name: "Loading...."}])

    useEffect(() => {
            const populate = async () => {
                const token = await getAccessTokenSilently();
                return new Promise(resolve => {
                        if (dropDown.length <= 1) {
                            fetch("https://gitlab-tool-api.dunelm.io/api/groups", {headers: {Authorization: `Bearer ${token}`}})
                                .then(res => {
                                    if (res.status === 200) {
                                        res.json()
                                            .then(async data => {
                                                long_poll("groups", data.uuid, token).then(r => {
                                                    resolve(JSON.parse(r.result))
                                                })
                                            })

                                    } else {
                                        res.json().then(json => {
                                                console.log(`Error: Status: ${res.status}, Error: ${json.message}`)
                                            }
                                        )
                                    }
                                }).catch(error => {
                                console.log(error)
                            })
                        }
                    }
                )
            }

            populate().then((data) => {
                setDropDown(data)
            })
        }, [getAccessTokenSilently, dropDown]
    )

    return (<DropDownContext.Provider value={dropDown}>{children}</DropDownContext.Provider>)
}

export default DropDownContext