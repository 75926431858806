import Nav from "./Nav";
import {DropDownProvider} from "../context/DropDownContext";

const App = () => {
    return (
        <DropDownProvider>
            <Nav/>
        </DropDownProvider>
    );

}

export default App;
