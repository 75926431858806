import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'bootstrap/dist/css/bootstrap.css';
import HomeTab from "./homeTab";
import CreateTab from "./createTab";
import ActionsTab from "./actionsTab";
import {withAuthenticationRequired} from '@auth0/auth0-react';
import Loading from "./loading";
import TableWithData from "./backupTableTab";

const Nav = () => {

    return (
        <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
        >
            <Tab eventKey="home" title="Home">
                <HomeTab/>
            </Tab>
            <Tab eventKey="display-data" title="Display Data">
                <TableWithData/>
            </Tab>
            <Tab eventKey="create" title="Create">
                <CreateTab/>
            </Tab>
            <Tab eventKey="freeze-actions" title="Freeze-actions">
                <ActionsTab/>
            </Tab>

        </Tabs>
    );
}

export default withAuthenticationRequired(Nav, {
    onRedirecting: () => <Loading/>,
});